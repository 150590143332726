import React from "react"
import SEO from "../components/seo"
import Layout from "../components/NewLayout"
import styled from "styled-components"


const subTitle = {
  fontSize: "1.5rem",
};

const divider = {
  display: "inline-block",
  width: "250px",
  height: "2px",
  background: "linear-gradient(to right,#000000 0,#000000 50%)",
  marginTop: "20px",
  marginBottom: "20px",
};

const iframeStyles = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%"
};

const AboutPage = (props) => (
  
<Layout>
  <SEO title="Home" />
  <Container>
    
    <TitleWrapper>
      <h1>Our Story.</h1>
      <p style={subTitle}>Deborah Robbins & Vicki Coleman opened the doors to Storm in 2006. The vision was to create a forward-thinking salon that welcomed clients to a warm and friendly environment, whilst providing quality hairdressing. After adding beauty treatments to their repertoire, Storm Hair and Beauty was born. Now an established high-end salon, providing many hair and beauty services to their clients.</p>
      <br/>
      <p>Storm Hair and Beauty takes pride in the effort taken by all team members to reduce our use of plastics and be as envrionmentally friendly as possible. We use scrummi sustainable hairdressing towels. These will biodegrade in 100 days, 10 times more absorbent than cotton and 100% hygienic. We continually make conscious decisions to source products from local companies that share our environmental and ethical values. This is why in 2018 we decided to become a Davines Salon.</p>   
      <span style={divider}></span>
      <br/>
      <ContentWrapper>
        <TextWrapper>
          <h2>Why we have chosen Davines</h2>
          <p>We have chosen to become a Davines salon because they create fantastic products and use sustainable ingredients creating a zero carbon footprint. They actively plant trees and nutritious hedges to feed and support wildlife always considering sustainiblity. This aligns with Storm Hair and Beautys business ethics. Davines encourages people to take care of themselves, of the environment in which they live and work and of the things they love.</p>
        </TextWrapper>
        <VideoWrapper>
          <iframe title="Beauty is our Responsibility - Davines" style={iframeStyles} src="https://www.youtube.com/embed/mXSdq3C9L7E" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </VideoWrapper>
      </ContentWrapper>
    </TitleWrapper>
    
    
  </Container>
</Layout>
)

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 1rem 4rem;
  margin-top: 3rem;
  p{
    font-weight: lighter;
    line-height: 1.9rem;
  }
  @media (max-width: 769px) {
    margin-top: 3rem;
    padding: 1rem 3rem;
  }
  @media (max-width: 469px) {
    margin-top: 8rem;
    padding: 1rem 1rem;
  }
`

const Container = styled.div`
    width: 100%;
    padding: 1rem 4rem;
    @media (max-width: 769px) {
      padding: 1rem 3rem;
    }
    @media (max-width: 469px) {
      padding: 1rem 1rem;
    }
`

const ContentWrapper = styled.div`
  margin-top: 2rem;
  @media (min-width: 1100px) {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(500px, 1fr) );
  grid-gap: 10px;
  margin-left: auto;
  margin-right: auto;
  justify-items: center;
  align-content: center;  
  max-width: 1400px;
  }
`

const TextWrapper = styled.div`
  align-self: center;
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  padding-right: 10em;
  @media (max-width: 1100px) {
    padding-right: 0;
  }


`
const VideoWrapper = styled.div`
  grid-column: 2;
  grid-row: 1;
  position: relative;
  padding-bottom: 56.25% /* 16:9 */;
  width: 100%;
`

export default AboutPage

